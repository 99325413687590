import React from "react"
import styled from "styled-components"

import { Row, Col } from "./style"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"
import { css } from "@emotion/core"
import { BookDemoButton } from "src/componentsV2/ui/Button"
import { openNewTab } from "src/utils/openNewTab"
import config from "src/config"

const AutoPolicyUpdatesContainer = styled(ContainerPage)`
  padding-top: 60px;
  padding-bottom: 60px;
  margin: 0 auto;
  color: white;
  background-color: #117BF3;
  @media (min-width: 768px) {
    padding-top: 120px;
    padding-bottom: 70px;
  }
`

const index = () => {
  return (
    <AutoPolicyUpdatesContainer data-loadable-component="auto-policy-updates-work-smarter">
      <WrapperPage>
        <Row className="items-center">
          <Col className="description">
            <Title className="title">
              <div className="text-white text-center">
              Work smarter, not harder.
              </div>
            </Title>
            <SubTitle className="px-4 md:px-0 mb-8 md:mb-15">
              <div className="text-white text-center">
              Book a call with our team to learn more about how AirMason’s Automated Policy Updates can work for your organization.
              </div>
            </SubTitle>
            <div>
              <BookDemoButton
                id="book-demo-button"
                onClick={() => openNewTab(config.CALENDLY.AUTOMATED_POLICY_UPDATES)}
                css={css`
                font-size: 16px;
                color: #117BF3;
                padding: 25px 0px;
                width: 150px;
                border-radius: 100px;
                margin: 0 auto;
                background-color: white;
                svg {
                  display: none;
                }
                `}
                title="Book a demo"
              />
            </div>
          </Col>
        </Row>
      </WrapperPage>
    </AutoPolicyUpdatesContainer>
  )
}

export default index
