import styled from "styled-components"

export const Title = styled.div`
  color: #0f1722;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 58px;
    text-align: left;
    margin-bottom: 30px;
  }
`

export const SubTitle = styled.div`
  color: #676b87;
  font-family: Lato;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;

  p {
    margin-bottom: 20px;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 30px;
    text-align: left;

    p {
      margin-bottom: 30px;
    }
  }
`
